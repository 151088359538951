import React from 'react'
import { withPreviewResolver } from 'gatsby-source-prismic'
import linkResolver from '../utils/linkResolver.js'
import '../styles/default.css'

const PreviewPage = ({ isPreview, isLoading, previewData }) => {
  return (
    <div className='text-3xl text-center font-black leading-tight md:text-6xl text-5xl z-10 relative flex flex-col justify-center h-screen'>
      {isPreview === false ? ( 'There\'s no preview here' ):( 'loading preview' )}
    </div>
  )
}

export default withPreviewResolver(PreviewPage, {
  repositoryName: 'thermoserv',
  linkResolver,
})
